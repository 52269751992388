.date-selection {
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-bottom: 20px;
}
.customAlert{
    max-width: 450px;
}
.date-box.active, .redirectbutton {
    background-color: #f00040;
    border: 2px solid #ddd;
    border-radius: 10px;
    padding: 10px;
    color: white;
    text-align: center;
    width: 110px;
    cursor: pointer;
    transition: background-color 0.3s ease, border-color 0.3s ease;
}
.redirectbutton{
    width: max-content;
    margin: 10px auto;
    display: flex;
}
.Toastify__toast-theme--light {
    box-shadow: 0 0 500px black;
}
.date-box{
    border: 2px solid #f00040;
    border-radius: 10px;
    padding: 10px;
    color: #f00040;
    text-align: center;
    width: 110px;
    cursor: pointer;
    transition: background-color 0.3s ease, border-color 0.3s ease;
}
.date-box span{
    font-size: 1rem;
    font-weight: 500;
    margin: 0px 2px;
}

.date-box.selected {
    background-color: #001f3f; /* Navy blue for the selected state */
    color: white; /* White text for selected date */
    border-color: #001f3f;
}

.date-box:hover {
    background-color: #f0f0f0;
    border-color: #c72020;
    font-weight: 600;
    color:rgb(242, 9, 48);
}

.button-container {
    display: flex;
    justify-content: center;
}

.custom-button {
    background-color: #f5f5f5;
    border: 2px solid #ddd;
    border-radius: 8px;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.custom-button:hover {
    background-color: #f0f0f0;
    border-color: #bbb;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}
.selected-date{
    /* border: 1px solid black; */
    font-size: 20px;
    font-weight: 700;
}

.ticket-count{
    border: 1px solid black;
}