/* General Carousel Container Styling */
.carousel-container {
    position: relative;
    max-width: 400px;
    aspect-ratio: 3/2;
  }
  
  .carousel-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    object-position: 10% center;
  }

  