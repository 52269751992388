@media (max-width: 320px){
    .tazza-logo{
        height:20px;
        margin-left: -13px;
    }
    .nav-items li{
        font-size: 12px;
    }
}
@media (max-width: 375px){
    .tazza-logo{
        height:20px;
        margin-left: -13px;
    }
    .nav-items li{
        font-size: 12px;
    }
}